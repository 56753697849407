@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  --foreground: white;
  --middle: #bbb;
  --background: linear-gradient(#0f0e26, #004d9c);
  --project-tile: rgba(15,14,38,1);
}

* {box-sizing: border-box}

html, body{
  background-color: white;
  /*background: url("/src/Dragon-Scales.svg");
  /* background by SVGBackgrounds.com */
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
  -ms-overflow-style: none;  /* IE no vert scrollbar*/
  scrollbar-width: none;  /* Firefox no vert scrollbar */
  height: 100vh;
}
.app-container{
  position: relative;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;

  background: linear-gradient(#0f0e26, #004d9c);

  background: var(--background);
  background-attachment:fixed;
}
/*remove vertical scrollbar*/
body::-webkit-scrollbar {
  display: none;
}
button {
  font-family: inherit; 
  font-size: 100%; 
  line-height: 1.15;
  margin: 0; 
  padding: 0;
  overflow: visible;
  text-transform: none;
  border: unset;
  color: unset;
  background-color: unset;
  cursor: pointer; 
}
.screen-reader-text {
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
}
.shower {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 100px;
  border:3px solid white;
  border:3px solid var(--foreground);
  opacity: .1;
  animation:  shower 30s linear infinite; 
  z-index: 0;
}
.shower.one {
  top: -10vh;
  right: 10vw;
}
.shower.two {
  width: 50px;
  height: 50px;
  top: -30vh;
  right: 50vw;
  animation-delay: 1.5s;
}
.shower.three {
  width: 30px;
  height: 30px;
  top: -40vh;
  right: -40vw;
  animation-delay: 3s;
}
@keyframes shower {
  0% {
    transform: translate(0,0) rotate(18deg) ;
  }
  100% {
    transform: translate(-120vw, 120vh) rotate(215deg) ;
  }
}
@keyframes bounce {
  0% {
    transform: rotate(18deg);
    top: 50vh;
    left: calc(100vw - 100px);
  }
  25% {
    transform: rotate(108deg);
    top: calc(100vh - 100px);
    left: 50vw;
  }
  50% {
    transform: rotate(18deg);
    top: 50vh;
    left: 0;
  }
  75% {
    transform: rotate(288deg);
    top: 0;
    left: 50vw;
  }
  100% {
    transform: rotate(18deg);
    top: 50vh;
    left: calc(100vw - 100px);
  }
}

p{
  font-size: 1rem;
  font-family: 'Nunito', sans-serif;
}
nav{
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  color: white;
  align-items: flex-end;
  min-height: 4.5rem;
}
#titlebar{
  font-size: 1.5rem;
  padding: 1rem .5rem 0 2rem;

}

a {
  position: relative;
  color: white;
  color: var(--foreground);
  text-decoration: none;
}

a:hover {
  color: white;
  color: var(--foreground);
}

nav a::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  background-color: var(--foreground);
  transform-origin: left;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

a:hover::before {
  transform: scaleX(1);
}
button .theme-switch svg {
  transition: all 1s ease-in-out;
}
button:hover .theme-switch svg,
button:focus-visible .theme-switch svg{
  animation: rotate 2s linear forwards infinite;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg)
  }
}
nav .hamburger {
    display: flex;
    place-content: center;
    font-size: 1.5rem;
    padding: 2rem;
    z-index: 2;
    cursor: pointer;
}

.ex {
  stroke:white;
  stroke:var(--foreground);
  stroke-width:3;
  stroke-linecap: round;
  transition: transform ease-in-out .5s;
  transform-origin: 50% 50%;
}
.top {
  transform: translateY(-25%)
}
.bottom {
  transform: translateY(25%)
}
.one {
  transform: rotate(45deg)  
}
.two {
  transform: scaleX(.1)
}
.three {
  transform: rotate(-45deg)
}

nav ul {
    list-style: none;
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    transform: translateX(300px);
    width: 50%;
    background: rgba(15,14,38,1);
    background: var(--project-tile);
    margin: 0;
    padding: 2rem;
    border-radius: 0 0 0 0.5rem;
    opacity: 0;
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    flex-direction: column;
    align-items: flex-start;
    z-index: 2;
    transition: transform .5s;
}
nav ul.nav-open{
  transform: translateX(0);
  opacity: 1;
  box-shadow: 0 0 5px rgba(0,0,0, .12);
  transition: transform .5s;



}
nav ul i {
    font-size: 2rem;
    padding: 1rem;
    cursor: pointer;
}

nav ul li {
    font-size: 1.5rem;
    width: 100%;
    display: grid;
    align-items: center;
}
.current {
  color: #bbb;
  color: var(--middle);
}
.current:hover {
  color: white;
  color: var(--foreground);
}

.primary-section{

  color: #4c4c4c;
  border-radius: 30px;
  padding: 2rem 2rem 0 2rem;

  display: flex;
  flex-direction: column;
  place-content: center;
  position: relative;
  z-index: 1;
}

#links {
    text-align: center;
    padding-top: 2rem;
}

#projects{
  font-size: 1em;
  color: #4c4c4c;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  flex-wrap: wrap;
  grid-gap: 4rem 2rem;
  gap: 4rem 2rem;
  max-width: 100rem;
  margin: 2rem auto 0;
}
.project-tile{
  padding: 0;
  width: 25%;
  min-width: 18.75rem;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  min-height: 22.5%;
  border-radius: 32px;
  box-shadow: 10px 10px 10px 0px rgba(0,0,0,0.1);
  
  color: white;
  
  color: var(--foreground);
  transition: .2s ease-out;
  position: relative;
  overflow: hidden;
  
}
.project-tile:hover{
  transform: translateY(-2%);
  transition: .5s cubic-bezier(0.4, -0.06, 0.32, 1.6);
}
.project-tile-contents {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.project-img {
  height: 55%;
  min-height: 12rem;
  background-size: cover;
  background-position: center;
}

.project-desc {
  height: 45%;
  min-height: 12rem;
  padding: 0 1rem;
  font-size: .9rem;
  background: rgba(15,14,38,1);
  background: var(--project-tile);
}
.project-title {
  font-size: 1.2rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.5rem;

}
.project-title h3 {
  font-size: 1rem;
  margin: .5rem 0;
}
.project-desc p {
  margin-top: .5rem;
  font-size: .75rem;
}
.project-link-placeholder {
  width: 19.2px;
}

#profile-pic {
    background-image: "../Profile.jpg"
}
.text-tile{
  font-size: 1rem;
  line-height: 1.6rem;
  padding: 2rem 2.5rem;
  border-radius: 1rem;
  max-width: 750px;
  margin: 2em auto;
  background: white;

}
.text-tile h1{
    font-size: 1.5rem;
    line-height: 1.5rem;
}
.text-tile a {
    font-size: 2rem;
    padding: .5rem;
    color: #4c4c4c;
    text-align: center;

}
ul {
  text-align: left;
}
li p {
  margin: 1rem;
}
.flex-cards {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 2em;
}
.skills-card {

  line-height: 2rem;
}

#spinner {
  fill: white;
  fill: var(--foreground);
}
#em1,
#em2,
#em3 {
  transform-origin: center;
}
#em1 {
  animation: bumpUp 1s both infinite;
}
@keyframes bumpUp {
  0% {
    opacity: 0;
    transform: translate(-17%, 38%);
  }
  50% {
    opacity: 1;
  }
  70% {
    transform: translate(-17%, 0%);
  }
  100% {
    
    transform: translate(0%, 0%)
  }
}
#em2 {
  animation: flipOver 1s both infinite;
}
@keyframes flipOver {
  0%, 10% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(180deg);
  }
}

#em3 {
  animation: duckOut 1s both infinite;
}
@keyframes duckOut {
  0%, 10% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(180deg) translate(-10%, -30%);
    opacity: 0;
  }
  
}
@media (min-width:600px){

p {
  font-size: 1.25rem;
  line-height: 1.6;
}

nav ul {
    list-style: none;
    position: relative;
    top: unset;
    right: unset;
    width: unset;
    height: unset;
    background: none;
    display: flex;
    flex-direction: row;
    grid-gap: 2rem;
    gap: 2rem;
    margin-right: 2rem;
    justify-content: space-around;
    align-items: center;
    font-size: .5rem;
    transform: translateX(0);
    opacity: 1;

}
nav ul.nav-open{
    display: flex;
    box-shadow: none;
}
nav ul li {
    width: auto;
    font-size: 1.2rem;
    margin: 0;
}
nav .hamburger {
    display: none;
}



}

@media (min-width: 900px){

  .primary-section{
    padding: 5rem 5rem 1rem 5rem;
  }
  .text-tile h1{
    font-size: 2rem;
    margin-bottom:2rem;
  }
  .text-tile{
    font-size: 1.4rem;
  }
  nav ul {
    grid-gap: 4rem;
    gap: 4rem;
  }
  nav ul li {
    font-size: 1.5rem;
    margin: 0;
  }


  .project-desc p {
    font-size: 1rem;
  }
  .project-title h3 {
    font-size: 1.1rem;
  }
  .skills-card h3 {
    font-size: 1.1rem;
  }
}


